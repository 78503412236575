import { ConnectionCard } from 'features/homeCards/connection__card'
import { OfflineCard } from 'features/homeCards/offline__card'
import { OnlineCard } from 'features/homeCards/online__card'
import { ZoneCard } from 'features/homeCards/zone__card'
import React from 'react'

export const AdminDashboard = () => {
  return (
    <div className="columns is-multiline mt-5 mx-2">
      <div className="column is-one-quarter">
        <ConnectionCard />
      </div>
      <div className="column is-one-quarter">
        <ZoneCard />
      </div>
      <div className="column is-one-quarter">
        <OnlineCard />
      </div>
      <div className="column is-one-quarter">
        <OfflineCard />
      </div>
    </div>


  )
}

