import { NavLink, NavLinkMultilevel } from "core/components/navLink";
import React from "react";

export const AdminSidebar = () => {
  return (
    <aside className="menu">
      <p className="menu-label">General</p>
      <ul className="menu-list">
        <NavLink link="dashboard">Dashboard</NavLink>
        <NavLink link="users">User</NavLink>
        <NavLink link="zones">Zone</NavLink>
        <NavLink link="companies">Company</NavLink>
        <NavLink link="nodes">Node</NavLink>
        <NavLink link="gateways">Gateway</NavLink>
        <NavLink link="reports">Report</NavLink>
        <NavLink link="alarms">Alarm History</NavLink>
        <NavLink link="generation">Data Generation</NavLink>
        <NavLink link="profile">Profile</NavLink>
        <NavLinkMultilevel value="Settings">
          <NavLink link="features">Feature</NavLink>
          <NavLink link="permissions">Permission</NavLink>
          <NavLink link="parameters">Parameter</NavLink>


        </NavLinkMultilevel>



      </ul>
    </aside>
  );
};
