import React from "react";
import { BreadcrumbItem } from "core/components/breadcrumb";
import { BreadcrumbContainer } from "core/components/breadcrumb";
import { PageHeader } from "core/components/pageHeader";
import { BatteryLevelReport } from "features/report/batteryLevelReport";

export const ReportAction = () => {
    return (
        <React.Fragment >
            <div className="content-container__header has-background-white">
                <PageHeader
                    header={"Report"}
                    description={"Interface for downloading reports"}

                />
                <BreadcrumbContainer>
                    <BreadcrumbItem label={"Home"} />
                    <BreadcrumbItem label={"Reports"} active={true} />
                </BreadcrumbContainer>
            </div>
            {/* /.content__header */}
            <div className="content-container__body has-background-white pb-5">
                <div className="dashboard">
                    <div className="dashboard__tab-content">
                        <BatteryLevelReport />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
