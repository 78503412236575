import { FormCard } from "core/cards/formCard";
import { SelectContainer } from "core/components/inputWithAccessControl";
import { EditTextField } from "core/components/inputWithAccessControl";
import { Modal } from "core/components/modal";
import { useLoading } from "core/hooks/loadingHook";
import { useSubmitForm } from "core/hooks/submitFormHook";
import { formatArrayToOptions } from "core/utility/util";
import { addDefaultOption } from "core/utility/util";
import { useMasterListState } from "features/masterList/masterListProvider";
import Joi from "joi";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import _ from "lodash";

import { useAxiosErrorHandlingHook } from "features/errorDisplay/axiosErrorHandlingHook";
import { addCompany, editcompany } from "./companyCrudService";

export const CompanyForm = ({ open, onClose, selectedData, onUpdateSuccess }) => {

  const { showError } = useAxiosErrorHandlingHook();
  const { loaderContainer, startLoading, stopLoading } = useLoading();
  const { zoneList } = useMasterListState();

  // Site Options
  const sortedZoneList = _.sortBy(zoneList, ["name"]);
  const zoneOptions = _.map(sortedZoneList, (zone) => {
    const { id, name } = zone;
    return { label: `${name}`, value: id };
  });
  const zoneNameOptions = addDefaultOption(zoneOptions);

  const schema = Joi.object({
    id: Joi.number().label("Id").positive().integer().allow(""),
    name: Joi.string().trim().label("Name").required(),
    plotNo: Joi.string().label("Plot No.").required(),
    contactPerson: Joi.string().trim().label("ContactPerson").required(),
    // contactNo: Joi.number().label("ContactNo").positive().required(),
    // emailId: Joi.string().trim().label("EmailId").required(),
    emailId: Joi.string()
      .trim()
      .email({ tlds: { allow: false } })
      .label("EmailId"),
    zoneId: Joi.number().label("ZoneId").positive().required(),
    connectionNumber: Joi.string().trim().label("Connection No.").required(),
    meterSize: Joi.number().label("Meter Size").positive().required(),
    meterSerialNo: Joi.string().trim().label("Connection No.").required(),
  });


  const accessToEdit = true;
  const submitCallback = async (event) => {
    const {
      id,
      name,
      plotNo,
      contactPerson,
      meterSerialNo,
      emailId,
      zoneId,
      connectionNumber,
      meterSize

    } = inputs;
    try {
      startLoading();

      const body = {
        id,
        name,
        plotNo,
        contactPerson,
        meterSerialNo,
        emailId,
        zoneId,
        connectionNumber,
        meterSize

      };
      // console.log(body);

      if (
        id === null ||
        id === undefined ||
        id === "" ||
        id === "0" ||
        id === 0
      ) {
        const { data: company } = await addCompany(body);
        onUpdateSuccess(company);
        toast.success("Company Added Successfully");
      } else {
        const { data: company } = await editcompany(id, body);
        onUpdateSuccess(company);
        toast.success("Company Edited Successfully");
      }
      stopLoading();
    } catch (error) {
      console.log(error);
      const toastMessage = "Error adding / editing company details";
      showError(error, toastMessage);

      stopLoading();
    }
    onClose();
  };
  let defaultInputs = { id: 0 };
  useEffect(() => {
    if (!_.isEmpty(selectedData)) {
      // console.log(selectedData);
      handleInputChange("id", selectedData["id"]);
      handleInputChange("name", selectedData["name"]);
      handleInputChange("plotNo", selectedData["plotNo"]);
      handleInputChange("contactPerson", selectedData["contactPerson"]);
      handleInputChange("meterSerialNo", selectedData["meterSerialNo"]);
      handleInputChange("emailId", selectedData["emailId"]);
      handleInputChange("zoneId", selectedData["zoneId"]);
      handleInputChange("connectionNumber", selectedData["connectionNumber"]);
      handleInputChange("meterSize", selectedData["meterSize"]);




    } else {
      resetInput();
      handleInputChange("id", "");
    }
  }, [selectedData]);

  const {
    inputs,
    errors,
    handleInputChange,
    handleSubmit,
    resetInput,
    additionalValidation,
  } = useSubmitForm(schema, submitCallback, defaultInputs);

  const FormHtml = (
    <FormCard
      formName={"Add/Update Company"}
      onSubmit={handleSubmit}
      onFormReset={resetInput}
      submitAccess={accessToEdit}
    >
      <div className="columns is-multiline">
        <div className="column is-one-third">
          <EditTextField
            identifier="id"
            labelName="Id"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            required={false}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <EditTextField
            identifier="name"
            labelName="Name "
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <EditTextField
            identifier="plotNo"
            labelName="Plot No."
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}


        <div className="column is-one-third">
          <EditTextField
            identifier="meterSerialNo"
            labelName="Meter Serial No."
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <EditTextField
            identifier="emailId"
            labelName="Email Id"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <EditTextField
            identifier="contactPerson"
            labelName="Contact Person"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}


        <div className="column is-one-third">
          <SelectContainer
            identifier="zoneId"
            labelName="Zone Name"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
            options={zoneNameOptions}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <EditTextField
            identifier="connectionNumber"
            labelName="Connection No"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <EditTextField
            identifier="meterSize"
            labelName="Meter Size"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}
      </div>
    </FormCard>
  );

  return (
    <Modal open={open} onClose={onClose} width="full-width">
      <div>{FormHtml}</div>
      {loaderContainer}
    </Modal>
  );
};
