import _ from "lodash";
import { toast } from "react-toastify";
import { useLoading } from "core/hooks/loadingHook";
import { downloadReport } from "core/utility/util";
import { generateReport } from "features/report/reportService";

const reportMap = [
    { name: "Battery Level Report", templateName: "lora_battery_level_report" },
    { name: "Data Interval Report", templateName: "lorawan_data_interval_report" },
    { name: "Device Status Report", templateName: "lorawan_device_status_report" }
]

export const BatteryLevelReport = () => {
    const { loaderContainer, startLoading, stopLoading } = useLoading();

    const handleReportDownload = async (templateName, name) => {
        startLoading();
        const body = {
            templateName: templateName,
            reportName: name,
            exportFormat: "xlsx",
            input: {

            }
        };
        try {
            const response = await generateReport(body);
            let fileName = response.headers["x-blob-file"];
            downloadReport(fileName, response);
            toast.success("Report generated successfully");
            stopLoading();
        } catch (error) {
            console.log(error);
            toast.error("Error while generating report");
            stopLoading();
        }
    };

    return (
        <div className="container">

            <div className="columns is-centered is-multiline">
                {reportMap.map((report, index) => (

                    <div className="column is-one-third">
                        <button className="button download-btn is-primary" onClick={() => handleReportDownload(report.templateName, report.name)}>
                            {report.name}
                            <span className="download-icon">
                                <img className="is-rounded" src={process.env.PUBLIC_URL + '/cardImages/download.png'} />
                            </span>
                        </button>
                    </div>))}
            </div>
            {loaderContainer}
        </div>

    );
};
