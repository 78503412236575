import React, { useEffect, useState } from "react";
import {
  logout,
  saveRefreshToken,
  saveToken,
  saveUser,
  updateAccessToken,
  validateRole,
  validateToken,
} from "features/authentication/authService";
import { toast } from "react-toastify";
import { AdminDashboard } from "./dashboard";
import { Navigate, Route, Router, Routes, useNavigate } from "react-router-dom";
import { PermissionProvider } from "features/user/permissionProvider";
import { AdminHeader } from "./adminHeader";
import { AdminSidebar } from "./adminSidebar";
import useWindowDimensions from "core/hooks/windowDimensionsHook";
import { getLoggedInUserDetails } from "features/user/userService";
import { useAxiosErrorHandlingHook } from "features/errorDisplay/axiosErrorHandlingHook";
import { AuthenticationValidator } from "features/authentication/authenticationValidator";
import roles from "roles.json";
import { ErrorBoundary } from "core/components/errorBoundary";
import { UserAction } from "./userActions";
import { ZoneAction } from "./zoneActions";
import { CompanyAction } from "./companyActions";
import { MasterlistProvider } from "features/masterList/masterListProvider";
import { UserProfile } from "./profile";
import { GatewayAction } from "./gatewayActions";
import { NodeAction } from "./nodeAction";
import { PermissionAction } from "./permissionAction";
import { FeatureAction } from "./featureAction";

import { AlarmAction } from "./alarmActions";
import { AdminNavbar } from "./adminNavbar";
import { ParameterAction } from "./parameterActions";
import { DataGenerationAction } from "./dataGenerationActions";
import { ReportAction } from "./reportAction";

export const Admin = (props) => {
  const { showError } = useAxiosErrorHandlingHook();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const { data: user } = await getLoggedInUserDetails();
      // console.log(user);
      saveUser(user);
    };
    try {
      fetchData();
    } catch (error) {
      console.log("Unable to fetch user information");
    }
  }, []);

  const handleLogout = () => {
    try {
      logout();
      navigate("/", { replace: true });
    } catch (error) {
      showError(error, "Could not logout");
    }
  };

  const { width, height } = useWindowDimensions();
  const [sidebar, setSidebar] = useState(width > 1024);
  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };
  let sidebarClass = "";
  if (sidebar) {
    sidebarClass = "sidebar";
  } else {
    sidebarClass = "sidebar-hidden";
  }
  const options = { requiredRole: roles.ADMIN_ROLE };
  return (
    <React.Fragment>
      <ErrorBoundary>
        <MasterlistProvider>
          <AuthenticationValidator options={options}>
            <PermissionProvider>
              <div className="main">
                <header className="header">
                  <AdminHeader
                    toggleSidebar={toggleSidebar}
                    onLogout={handleLogout}
                  />
                </header>
                <div className="content-area">
                  <div className={`${sidebarClass}`}>
                    <AdminSidebar />
                  </div>
                  <div className="content-container">
                    <Routes>
                      <Route path="dashboard" element={<AdminDashboard />} />
                      <Route path="users" element={<UserAction />} />
                      <Route path="zones" element={<ZoneAction />} />
                      <Route path="companies" element={<CompanyAction />} />
                      <Route path="nodes" element={<NodeAction />} />
                      <Route path="features" element={<FeatureAction />} />
                      <Route exact path="users" component={<UserAction />} />

                      <Route path="permissions" element={<PermissionAction />} />
                      <Route path="gateways" element={<GatewayAction />} />
                      <Route path="alarms" element={<AlarmAction />} />
                      <Route path="parameters" element={<ParameterAction />} />
                      <Route path="generation" element={<DataGenerationAction />} />
                      <Route path="profile" element={<UserProfile {...props} />} />
                      <Route path="reports" element={<ReportAction />} />
                      <Route path="/" element={<Navigate to="dashboard" />} />
                    </Routes>
                  </div>
                  {/* /.content-container */}
                </div>
                {/* /.content-area */}
              </div>
              {/* /.main */}
            </PermissionProvider>
          </AuthenticationValidator>
        </MasterlistProvider>
      </ErrorBoundary>
    </React.Fragment>
  );
};
